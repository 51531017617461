<template>
  <v-container>
    <h1
      v-if="!message"
      class="text-center mt-auto"
    >
      Loading..... Please wait
    </h1>
    <v-alert v-else>
      {{ message }}
    </v-alert>
  </v-container>
</template>

<script>
import axios from '@axios'

export default {
  data() {
    return {
      message: null,
    }
  },
  mounted() {
    // for testing
    // this.$cookie.set('dvbzsslg', 1)
    // this.$cookie.set('attkn', '160|iHPu0Zbhpu89cOL34d8b2UAaNB3x4GqKSsCpoLyl')

    // fetch cookie and validate
    const token = this.$cookie.get('attkn')

    // const check = this.$cookie.get('dvbzsslg')
    if (token) {
      const oldToken = localStorage.getItem('accessToken')

      localStorage.setItem('accessToken', token)

      axios
        .get('auth/user?ignore=1')
        .then(response => {
          this.setAuth(response)
        })
        .catch(() => {
          this.authCheckOld(oldToken)
        })
    } else {
      this.message = 'Token not found'
    }

    // this.$cookie.set('attkn', 1, { domain: '.divbyzero.com' })
    // this.$cookie.set('attkn', 1, { domain: '.divbyzero.com' })

    // cookie 1
    // dvbzsslg

    // cookie 2 token
    // attkn
  },
  methods: {
    authCheckOld(token) {
      localStorage.setItem('accessToken', token)
      axios
        .get('auth/user?ignore=1')
        .then(response => {
          this.setAuth(response)
        })
        .catch(error => {
          console.log(error)
          this.$router.push('/login')
        })
    },
    setAuth(response) {
      console.log('setting auth:', response.data)
      const user = response.data

      const { ability: userAbility } = user

      // Set user ability
      // ? https://casl.js.org/v5/en/guide/intro#update-rules
      this.$ability.update(userAbility)

      // Set user's ability in localStorage for Access Control
      localStorage.setItem('userAbility', JSON.stringify(userAbility))

      // We will store `userAbility` in localStorage separate from userData
      // Hence, we are just removing it from user object
      delete user.ability

      // Set user's data in localStorage for UI/Other purpose
      localStorage.setItem('userData', JSON.stringify(user))

      // Set user's data in store auth
      this.$store.dispatch('auth/setUserData', user)
      this.$store.dispatch('tracking/logActivity', { activityName: 'Login' })

      const redirectURL = this.$route.query.to || '/'
      this.$router.push(redirectURL)
    },
  },
}
</script>
